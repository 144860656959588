<template>
    <div class="app-content">
        <el-card
            class="box-card"
            style="margin-top: 10px"
            v-if="$route.query.id"
        >
            <div slot="header" class="clearfix">
                <el-row type="flex" justify="space-between" :gutter="24">
                    <el-col :span="16"> <b>报名信息</b></el-col>
                    <el-col :span="8" style="text-align: right">
                        <el-button type="primary" @click="deriveClick"
                            >导出</el-button
                        >
                    </el-col>
                </el-row>
            </div>
            <el-table :data="reportData" border style="width: 100%">
                <el-table-column prop="applyTime" label="报名时间" width="150">
                </el-table-column>
                <el-table-column prop="userName" label="姓名">
                </el-table-column>
                <el-table-column prop="unit" label="单位"> </el-table-column>
                <el-table-column prop="userPosition" label="职位">
                </el-table-column>
                <el-table-column prop="phone" label="联系方式">
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div>
                <Pagination
                    v-bind:child-msg="pageparm"
                    @callFather="callFather"
                ></Pagination>
            </div>
        </el-card>
    </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { getTrainActivitySignUpInfoApi } from '@/api/training'

export default {
    // 注册组件
    components: {
        Pagination,
    },
    data() {
        return {
            pageparm: {
                currentPage: 1,
                pageSize: 10,
                total: 20,
            },
            reportData: [],
        }
    },
    created() {
        if (this.$route.query.id) {
            this.getTrainActivitySignUpInfoFun()
        }
    },
    methods: {
        // 获取报告详情
        async getTrainActivitySignUpInfoFun(currentPage, pageSize) {
            var start = currentPage ? currentPage * pageSize - pageSize : 0
            var params = {
                start: start,
                length: pageSize ? pageSize : 10,
                draw: 1,
                activityId: this.$route.query.id,
            }
            var res = await getTrainActivitySignUpInfoApi(params)
            console.log('res', res);
            this.reportData = res.data
            this.pageparm.total = res.recordsTotal
            // console.log('报告详情', res);
        },

        // 分页插件事件
        callFather(parm) {
            this.getTrainActivitySignUpInfoFun(parm.currentPage, parm.pageSize)
        },

        // 导出报告
        deriveClick() {
          if(this.reportData.length>0){
            this.download(
                '/doftec/trainActivityUser/list/' + this.$route.query.id,
                this.$route.query.activityName + '导出报名信息.xlsx'
            )
          }else{
            this.$message({
                    message: '无人报名，无法导出',
                    type: 'error',
                })
          }
            
        },
    },
}
</script>

<style scoped>
.app-content {
    width: 1200px;
    margin: 20px auto 20px auto;
}
::v-deep .el-form-item {
    width: 100%;
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 100%;
}
</style>
